@use 'styles/theme';

.announcement {
  border: 1px solid #0e7377;
  border-radius: 20px;
  margin-bottom: 1.5rem;
  padding: 2rem;
  width: inherit;

  .heading {
    font-size: 1.17rem;
    font-weight: bold;
    padding-bottom: 1rem;
  }
}
