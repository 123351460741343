@use 'styles/theme';

.container {
  text-align: left;
}

.section {
  padding: 2rem 1.5rem;

  &:last-child {
    padding-bottom: calc(2rem + #{theme.$content-border-radius});
  }

  @include theme.for-desktop-up {
    padding: 4.15rem 1.5rem;

    &:last-child {
      padding-bottom: calc(4.5rem + #{theme.$content-border-radius});
    }
  }
  &Hero {
    padding-top: 2rem;
  }
  &Announcements {
    padding-bottom: 1rem;
    padding-top: 2rem;
  }
  &LightBlue {
    background-color: theme.$color-pale-blue;
  }
  &LightTeal {
    background-color: theme.$color-light-teal;
  }
  &Cards {
    .container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include theme.for-desktop-up {
        flex-direction: row;
      }
    }
  }
  &LgPaddingTop {
    @include theme.for-desktop-up {
      padding-top: 6.5rem;
    }
  }
  &NoPaddingBottom {
    padding-bottom: 0;
  }
  &NoPaddingTop {
    padding-top: 0;
  }

  .container {
    position: relative;

    @include theme.for-tablet-up {
      margin: 0 auto;
      width: 100%;
    }
    @include theme.for-desktop-up {
      margin: 0 auto;
      max-width: 1270px;
    }
  }
}
.hero {
  border-radius: 14px;
  overflow: hidden;

  @include theme.for-tablet-up {
    border-radius: 40px;
  }
  @include theme.for-desktop-up {
    max-height: 565px;
  }

  img {
    display: block;
    width: 100%;
  }
}
.welcome {
  display: none;

  @include theme.for-tablet-up {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 2rem;
  }
}

/* Cards */
.card {
  background-color: theme.$color-white;
  border: 1px solid #a0dab3;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  margin-top: 1.75rem;
  padding: 2rem 1.5rem;
  text-align: left;

  .button {
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 1rem;
  }
  @include theme.for-iphone678-landscape {
    margin-left: auto;
    margin-right: auto;
    width: 468px;
  }
  @include theme.for-tablet-up {
    height: 432px;
    margin: 1rem 0;
    padding: 3.5rem;
    width: 468px;

    .button {
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      width: 80%;
    }
  }

  @include theme.for-desktop-up {
    border-color: theme.$color-teal;
    border-radius: 20px;
    margin: 0 2.5rem;
    max-width: 488px;
    padding: 2.75rem 2.667rem 2.667rem 2.667rem;
    width: inherit;
  }

  .content,
  form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  h2 {
    margin-bottom: 0.75rem;
  }
  p {
    margin-bottom: 1rem;

    @include theme.for-tablet-up {
      font-size: 16px;
    }
    @include theme.for-desktop-up {
      font-size: 18px;
    }
  }
  .text-field {
    margin: 1.75rem 0 1rem 0;
  }

  .link {
    align-items: center;
    color: theme.$color-darkest-gray;
    display: inline-flex;
    font-family: theme.$font-family-secondary;
    font-size: 0.778rem;
    font-weight: bold;
    line-height: 1em;
    margin-bottom: 1rem;
    text-decoration: none;

    svg {
      font-size: 1.25em;
    }
  }
}
