@use 'styles/theme';

.header {
  align-items: center;
  background-color: theme.$color-white;
  border-bottom: 1px solid theme.$color-light-gray;
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  z-index: 1000;

  @include theme.for-tablet-up {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }

  .container {
    align-items: center;
    display: flex;
    height: 70px;
    justify-content: space-between;

    @include theme.for-tablet-up {
      margin: 0 auto;
      width: 100%;
    }
    @include theme.for-desktop-up {
      margin: 0 auto;
      max-width: 1400px;
    }
  }
}

.mobileNav {
  background-color: theme.$color-white;
  border-bottom: 1px solid theme.$color-light-gray;
  height: 70px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
}

.logo {
  img {
    margin-top: 0.5rem;
    max-width: 120px;
  }
}

/* Nav */
nav {
  flex-grow: 1;
  height: 100%;
  overflow-x: auto;
  padding-top: 1px;

  @include theme.for-tablet-up {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  ul {
    display: flex;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0;
    }
  }
  a {
    align-items: center;
    color: theme.$color-comptia-black;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    height: 100%;
    line-height: 1.5;
    padding: 0 24px;
    position: relative;
    text-decoration: none;
    white-space: nowrap;

    &::after {
      $active-border-height: 4px;

      background-color: transparent;
      border-top-left-radius: $active-border-height;
      border-top-right-radius: $active-border-height;
      bottom: 0;
      content: '';
      height: $active-border-height;
      left: 0;
      position: absolute;
      width: 100%;
    }

    @include theme.for-tablet-up {
      margin: 0 24px;
      padding: 0;
    }

    &:hover,
    &.active {
      &::after {
        background-color: theme.$color-blue;
      }
    }
  }
}
