@use 'styles/theme';

.banner {
  background-color: theme.$color-primary;
  border-bottom-left-radius: theme.$content-border-radius-small;
  border-bottom-right-radius: theme.$content-border-radius-small;
  color: theme.$color-white;
  display: flex;
  font-family: theme.$font-family-secondary;
  justify-content: center;
  line-height: 1.5em;
  text-align: left;
}

.container {
  align-items: center;
  display: grid;
  font-size: 1rem;
  gap: 1rem;
  padding: 1rem;

  .button {
    width: 200px;

    @media only screen and (max-width: 767px) {
      width: 160px;
    }
  }

  @include theme.for-tablet-up {
    font-size: 1.1rem;
    gap: 2.5rem;
    grid-template-columns: auto 1fr;
    max-width: 768px;
  }
}
