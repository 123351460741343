@use 'styles/theme';

.spinner {
  display: block;
  height: 3rem;
  margin-left: auto;
  margin-right: auto;
  width: 3rem;
}

.trainingProducts {
  text-align: left;

  .title {
    font-family: theme.$font-family-primary;
    font-size: 0.667rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;

    @include theme.for-tablet-up {
      font-size: 1rem;
    }
  }
  .product {
    border-bottom: 1px solid #e8eaed;
    color: theme.$color-rich-gray;
    display: block;
    font-family: theme.$font-family-primary;
    margin: 0 0.5rem;
    padding: 1rem 1.5rem 1rem 0;
    position: relative;
    text-decoration: none;

    @include theme.for-tablet-up {
      font-size: 1.5rem;
    }

    span {
      margin-right: 0.25rem;
    }

    &Type {
      font-size: 0.5rem;
      letter-spacing: 1.4px;
      text-transform: uppercase;

      @include theme.for-tablet-up {
        font-size: 0.7rem;
      }
    }
    &CourseName {
      font-size: 0.889rem;
      letter-spacing: 0.2px;
      margin-top: 0.4rem;

      @include theme.for-tablet-up {
        font-size: 1rem;
      }
    }
    &ExpirationDate {
      font-size: 0.667rem;
      margin-top: 0.4rem;

      @include theme.for-tablet-up {
        font-size: 0.8rem;
      }

      &.expired {
        color: theme.$color-comptia-red;
      }
    }
    &Carret {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      svg {
        font-size: 1.15rem;
        opacity: 0.75;
      }
    }
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
  .expired {
    cursor: default;
    opacity: 0.6;
  }
  .waiting {
    color: theme.$color-comptia-red;
  }
}
