@use 'styles/theme';

.onboarding {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1001;

  &Secure {
    background-color: #f5ecf9;
  }
  &Connected {
    background-color: #fed6b0;
  }
  &Simple {
    background-color: #d8f2f1;
  }

  .closeButton {
    position: absolute;
    right: 25px;
    top: 25px;

    svg {
      color: theme.$color-rich-gray;
      cursor: pointer;
      font-size: 30px;
    }
  }
  .card {
    margin: 0 25px;
    min-height: 400px;
    padding: 2rem;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 1s;
    width: calc(100% - 50px);

    @include theme.for-tablet-up {
      padding: 2rem;
      position: relative;
      width: 50%;
    }
    @include theme.for-desktop-up {
      min-height: 500px;
      padding: 2rem;
      position: relative;
      width: 350px;
    }

    &:hover {
      transform: rotateY(180deg);
      transition: transform 0.5s;
    }
    .cardIcon {
      svg {
        font-size: 54px;
        left: 24px;
        position: absolute;
        top: 24px;
      }
    }
    .learnMore {
      border-radius: 50%;
      color: theme.$color-comptia-black;
      display: block;
      font-family: theme.$font-family-gotham;
      font-size: 14px;
      height: 96px;
      margin: 90px auto 45px auto;
      text-transform: uppercase;
      width: 96px;

      &Secure {
        background-color: #f5ecf9;

        &:hover {
          background-color: darken(#f5ecf9, 10%);
        }
      }
      &Connected {
        background-color: #fed6b0;

        &:hover {
          background-color: darken(#fed6b0, 10%);
        }
      }
      &Simple {
        background-color: #d8f2f1;

        &:hover {
          background-color: darken(#d8f2f1, 10%);
        }
      }
    }
    .title {
      font-family: theme.$font-family-gotham;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .description {
      font-family: theme.$font-family-roboto;
      font-size: 14px;
      line-height: 20px;
    }

    &Front,
    &Back {
      backface-visibility: hidden;
      background-color: theme.$color-white;
      border-radius: 20px;
      height: 100%;
      left: 0;
      padding: 25px;
      position: absolute;
      text-align: left;
      top: 0;
      width: 100%;
    }
    &Back {
      transform: rotateY(180deg);
    }

    &Secure {
      .cardFront,
      .cardBack {
        border: 5px solid #cf9ade;

        .cardIcon svg,
        .title {
          color: #582c83;
        }
      }
    }
    &Connected {
      .cardFront,
      .cardBack {
        border: 5px solid #feae62;

        .cardIcon svg,
        .title {
          color: #db7e08;
        }
      }
    }
    &Simple {
      .cardFront,
      .cardBack {
        border: 5px solid #0e7377;

        .cardIcon svg,
        .title {
          color: #0e7377;
        }
      }
    }
  }

  /* React Responsive Carousel */
  :global {
    .carousel {
      &.carousel-slider {
        overflow: visible;
      }
      .slide {
        > div {
          display: flex;
          justify-content: center;
        }
      }
      .control-dots {
        bottom: -50px;

        .dot {
          background: #000;
          box-shadow: none;
          height: 1rem;
          margin: 0 0.75rem;
          opacity: 0.4;
          width: 1rem;
        }
      }
    }
  }
}
