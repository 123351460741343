@use 'styles/theme';

.footer {
  background-color: #243745;
  border-bottom-left-radius: theme.$content-border-radius;
  border-bottom-right-radius: theme.$content-border-radius;
  border-top-left-radius: theme.$content-border-radius;
  border-top-right-radius: theme.$content-border-radius;
  color: theme.$color-white;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: space-between;
  margin-top: auto;
  min-height: 264px;
  outline: none;
  padding: 2.5rem 1.75rem 6rem 1.75rem;
  position: relative;

  @include theme.for-tablet-up {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  @include theme.for-tablet-landscape {
    min-height: 310px;
  }
  @include theme.for-desktop-up {
    padding: 2rem;
  }
  @include theme.for-iphone678-landscape {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      text-align: left;
    }
    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }
    @include theme.for-tablet-up {
      margin: 0 auto;
      width: 100%;
    }
    @include theme.for-desktop-up {
      margin: 0 auto;
      max-width: 880px;
    }
  }
  .footerLogo {
    margin-bottom: 1.75rem;
    max-width: 145px;

    @include theme.for-tablet-up {
      margin-bottom: 2.75rem;
    }
  }
  .mobileShift {
    @media only screen and (max-width: 1024px) {
      border-top: 1px solid #4d606f;
      bottom: 1.75rem;
      left: 1.75rem;
      padding-top: 0.75rem;
      position: absolute;
      text-align: center;
      width: calc(100% - 3.5rem);
    }
    @include theme.for-tablet-landscape {
      bottom: 0.75rem;
    }
  }
  address {
    font-size: 0.667rem;
    font-style: normal;
    line-height: 1.15rem;
    opacity: 0.5;

    @include theme.for-tablet-up {
      display: block;
    }
  }
  .title {
    font-size: 0.667rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  .navList {
    margin: 1.35rem 0 3rem 0;
    padding: 0 0 0 0.888rem;

    li {
      list-style: none;
      margin: 1rem 0;
    }
    a {
      color: theme.$color-white;
      text-decoration: none;
    }
  }
  .socialIcons {
    padding: 0.5rem 0;

    a {
      &:first-child {
        margin-left: -0.75rem;
      }
    }
  }
}
