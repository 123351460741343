@use 'styles/theme';

.header {
  @include theme.for-tablet-up {
    $max-width: 463px;

    padding-left: calc((100vw - #{$max-width}) / 2);
    padding-right: calc((100vw - #{$max-width}) / 2);
  }

  @include theme.for-desktop-up {
    $max-width: 631px;

    padding-left: calc((100vw - #{$max-width}) / 2);
    padding-right: calc((100vw - #{$max-width}) / 2);
  }
}

.message {
  border: 1px solid theme.$color-comptia-red;
  color: theme.$color-comptia-red;
  margin: 30px 0 20px;
  padding: 10px;
  text-align: left;

  .icon {
    color: theme.$color-comptia-red;
    display: inline-block;
    margin-right: 5px;
    vertical-align: bottom;
  }
}

.description {
  border-bottom: 1px solid theme.$color-border-gray;
  margin-bottom: 20px;
  padding-bottom: 20px;
  text-align: left;

  .icon {
    color: theme.$color-comptia-red;
    margin-right: 5px;
    vertical-align: bottom;
  }

  small {
    font-size: 14px;
  }
}

.required {
  color: theme.$color-rich-gray;
  display: block;
  font-size: 12px;
  margin-bottom: 20px;
  text-align: left;
}

.grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;

  > * {
    grid-column: span 12;
  }

  .suffix,
  .zip {
    grid-column: span 4;
  }

  .code {
    grid-column: span 4;
  }

  .phone {
    grid-column: span 8;
  }

  .birth-month {
    grid-column: span 6;
  }

  .birth-year {
    grid-column: span 6;
  }
}

.alternate {
  text-align: left;

  @include theme.for-tablet-up {
    margin-top: -1rem;
  }
}

.button {
  margin-top: 20px;
}
