@use 'styles/theme';

/* Access Your Training */
.container {
  position: relative;
  width: 100%;

  @include theme.for-tablet-up {
    margin: 0 auto;
    width: 468px;
  }
  @media only screen and (min-width: 1270px) and (max-width: 1324px) {
    width: 100%;
  }
  @media only screen and (min-width: 1325px) {
    width: 1270px;
  }
}

.accessYourTraining {
  background-color: #b1e5e4;
  border-radius: 20px;
  flex-direction: row;
  position: relative;

  @media only screen and (min-width: 1270px) {
    display: flex;
    margin: 0;
    min-height: 364px;
    padding: 2rem 4rem 2rem 540px;
  }

  img {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    width: 100%;

    @media only screen and (min-width: 1270px) {
      border-bottom: 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      bottom: 0;
      left: 100px;
      max-width: 100%;
      position: absolute;
      width: inherit;
    }
  }

  &ComingSoon {
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-template-areas:
      'heading'
      'content'
      'button';
    padding: 1.5rem 1.5rem 1.75rem;

    h2 {
      grid-area: heading;
      margin: 0;
    }

    a {
      grid-area: button;
    }

    &Content {
      grid-area: content;
    }

    @media only screen and (min-width: 1270px) {
      column-gap: 2rem;
      display: grid;
      grid-auto-rows: min-content;
      grid-template-areas:
        'heading button'
        'content content';
      grid-template-columns: auto 1fr;
    }
  }

  &Details {
    display: grid;
    font-size: 16px;
    padding: 1.5rem 1.5rem 1.75rem;
    width: 100%;

    @media only screen and (min-width: 1270px) {
      grid-template-rows: auto 1fr;
      padding: 0;
    }

    h2 {
      font-weight: 600;
      margin-bottom: 5px;
    }
    p {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }
    .button {
      margin-top: 1rem;

      @media only screen and (min-width: 1270px) {
        margin: 1rem 1rem;
        max-width: 200px;
        white-space: nowrap;
      }
    }
  }

  &Top {
    @media only screen and (min-width: 1270px) {
      align-items: center;
      display: grid;
      gap: 0;
      grid-template-columns: auto 1fr;
    }
  }
  &Bottom {
    clear: both;

    @media only screen and (min-width: 1270px) {
      align-items: center;
      display: flex;
    }

    div {
      @media only screen and (min-width: 1270px) {
        width: 50%;

        &:first-child {
          padding-right: 25px;
        }
        &:last-child {
          padding-left: 25px;
        }
      }
    }
  }

  .spinner {
    display: block;
    height: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    width: 2.5rem;

    @include theme.for-tablet-up {
      margin-top: -1.75rem;
    }
  }

  .linksList {
    margin: 0;
    padding: 0;
    width: 100%;

    @media only screen and (min-width: 1270px) {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr;
    }

    a {
      align-items: center;
      border-bottom: 1px solid rgba($color: theme.$color-white, $alpha: 0.5);
      color: theme.$color-comptia-black;
      display: flex;
      font-family: theme.$font-family-gotham;
      font-size: 15px;
      font-weight: 600;
      justify-content: space-between;
      line-height: 0.85rem;
      padding: 15px 0;
      text-decoration: none;

      svg {
        color: #004f71;
        font-size: 1.15rem;
        opacity: 0.5;
      }
      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
    li {
      list-style: none;

      @media only screen and (min-width: 1270px) {
        &:nth-child(odd) {
          a {
            margin-right: 2.5rem;
          }
        }
        &:nth-child(even) {
          a {
            margin-left: 2.5rem;
          }
        }
      }
    }
    span {
      flex-grow: 1;
      line-height: 1.1rem;
      margin-left: 0.75rem;
    }
  }

  .waiting {
    color: #c8102e;
    display: block;
    font-size: 0.5rem;
    margin-left: 0 !important;
  }

  .external {
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    display: grid;
    gap: 1rem;
    grid-template-columns: auto 1fr;
    margin-bottom: 10px;
    padding: 15px;

    p {
      font-size: 0.85rem;
    }

    .button {
      margin: 0;
      padding: 1rem 1rem;
    }
  }
}
