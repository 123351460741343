@use 'styles/theme';

.profileMenu {
  position: relative;

  &Dropdown {
    background-color: theme.$color-white;
    border: solid 1px theme.$color-light-gray;
    border-radius: 8px;
    box-shadow: 0 4px 8px 3px rgba(60, 64, 67, 0.15),
      0 1px 3px 0 rgba(60, 64, 67, 0.3);
    color: theme.$color-darkest-gray;
    padding: 1rem 1.5rem;
    position: absolute;
    right: 0;
    top: 40px;
    width: calc(100vw - 2rem);
    z-index: 1000;

    @include theme.for-tablet-up {
      width: 312px;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }
    .close {
      background: none;
      border: none;
      border-radius: 0;
      color: theme.$color-rich-gray;
      cursor: pointer;
      font-size: 22px;
      height: 1.5rem;
      padding: 0;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 1.5rem;

      &:hover {
        background: none;
        color: theme.$color-comptia-black;
      }
    }
  }
  &Details {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
  }
  .profileInitials,
  .profileInitialsSmall {
    border: none;
  }
  .profileInitialsSmall {
    cursor: pointer;
    font-size: 12px;
    height: 32px;
    width: 32px;

    &:hover {
      background: darken(theme.$color-pink, 5%);
    }
  }
  .profileInitials {
    align-items: unset;
    line-height: 2.65rem;
  }

  &Name {
    font-family: theme.$font-family-gotham;
    font-size: 18px;
    font-weight: 600;
  }
  &EmailAddress,
  &CompTIAID {
    font-size: 1rem;
    line-height: 1rem;

    @include theme.for-tablet-up {
      font-size: 0.8rem;
      line-height: 0.8rem;
    }
  }
  .menuList {
    font-family: theme.$font-family-gotham;
    font-size: 13px;
    list-style: none;
    margin: 1.5rem 0 1rem 0;
    padding: 0;

    li {
      border-bottom: 1px solid theme.$color-light-gray;
      padding: 0.75rem 0;
    }
    a {
      align-items: center;
      color: theme.$color-dark-gray;
      display: flex;
      font-weight: 600;
      position: relative;
      text-decoration: none;
    }
    svg {
      font-size: 20px;
      margin-right: 0.5rem;
    }
    .arrowForward {
      color: theme.$color-icon-gray;
      font-size: 18px;
      position: absolute;
      right: -5px;
    }
  }
  .button {
    border-color: theme.$color-dark-gray;
    color: theme.$color-dark-gray;
    font-size: 14px;
    height: 36px;
  }
  .footer {
    align-items: center;
    color: theme.$color-dark-gray;
    display: flex;
    font-size: 12px;
    justify-content: center;
    margin-top: 20px;

    a {
      color: theme.$color-dark-gray;
      margin: 0 7.5px;
      text-decoration: underline;
    }
  }
}
