.iconContainer {
  display: flex;
  justify-content: center;
}

.grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;

  > * {
    grid-column: span 12;
  }

  .textField {
    grid-column: span 6;
  }

  .suffix,
  .zip {
    grid-column: span 6;
  }

  .birthDate {
    grid-column: span 4;
  }

  .code {
    grid-column: span 3;
  }

  .phone {
    grid-column: span 9;
  }

  .button {
    margin-top: 2rem;
  }
}

.alternate {
  text-align: left;
}
