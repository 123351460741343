@use 'styles/theme';

.edit {
  background: none;
  background-color: theme.$color-blue;
  border: none;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  padding: 6px 20px;

  &:hover {
    background-color: darken(theme.$color-primary, 5%);
  }

  &.cancel {
    background-color: theme.$color-light-gray;
    color: theme.$color-dark-gray;
    &:hover {
      background-color: darken(theme.$color-light-gray, 5%);
    }
  }
}
.grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;

  > * {
    grid-column: span 12;
  }
}

.button {
  margin-top: 20px;
}

.social {
  text-align: left;

  .heading {
    display: block;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 1.25rem;
    margin: 0 0 1rem;
    text-transform: uppercase;

    @include theme.for-tablet-up {
      font-size: 0.889rem;
      line-height: 1.111rem;
      margin-bottom: 1.556rem;
    }
  }
}

.required {
  color: theme.$color-rich-gray;
  display: block;
  font-size: 12px;
  margin-bottom: 1em;
  opacity: 0.6;
  text-align: left;
}
