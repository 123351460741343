@use 'styles/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.header {
  padding: 3rem 1.25rem;

  @include theme.for-tablet-up {
    padding: 3rem 0;
  }

  &.has-subtitle {
    padding-bottom: 1.5rem;
  }

  h1 {
    margin: 0;

    + p {
      margin: 8px 0 0;

      @include theme.for-tablet-up {
        margin-top: 0;
      }
    }
  }

  @include theme.for-tablet-up {
    $max-width: 463px;

    padding-left: calc((100vw - #{$max-width}) / 2);
    padding-right: calc((100vw - #{$max-width}) / 2);
  }

  @include theme.for-desktop-up {
    $max-width: 631px;

    padding-left: calc((100vw - #{$max-width}) / 2);
    padding-right: calc((100vw - #{$max-width}) / 2);
  }
}

.content {
  background-color: theme.$color-lightest-gray;
  border-top-left-radius: theme.$content-border-radius-small;
  border-top-right-radius: theme.$content-border-radius-small;
  display: flex;
  flex-direction: column;
  padding: 2rem 23px calc(4rem + #{theme.$content-border-radius});

  @include theme.for-tablet-up {
    $max-width: 463px;

    border-top-left-radius: theme.$content-border-radius;
    border-top-right-radius: theme.$content-border-radius;
    flex: 1;
    padding-left: calc((100vw - #{$max-width}) / 2);
    padding-right: calc((100vw - #{$max-width}) / 2);
  }

  @include theme.for-desktop-up {
    $max-width: 631px;

    padding-left: calc((100vw - #{$max-width}) / 2);
    padding-right: calc((100vw - #{$max-width}) / 2);
  }
}
