@use 'styles/theme';

/* Exploring Launchpad */
.section {
  padding: 2rem 1.5rem;

  &:last-child {
    padding-bottom: calc(2rem + #{theme.$content-border-radius});
  }

  @include theme.for-tablet-up {
    padding: 4.5rem 1.5rem;

    &:last-child {
      padding-bottom: calc(4.5rem + #{theme.$content-border-radius});
    }
  }
}
.exploreLaunchpad {
  background-image: url(../../images/woman-folded-arms.jpg);
  background-position: 60% bottom;
  background-repeat: no-repeat;
  background-size: 75%;
  padding-bottom: 500px !important;

  @include theme.for-mobile-landscape {
    background-size: 45%;
  }
  @include theme.for-iphone678-landscape {
    background-size: 40%;
  }
  @include theme.for-tablet-up {
    background-position: 95% bottom;
    background-size: 45%;
    padding-bottom: 525px !important;
  }
  @include theme.for-desktop-up {
    background-position: 78% bottom;
    background-size: inherit;
    min-height: 750px;
    padding-bottom: inherit !important;
  }

  .container {
    @include theme.for-iphone678-landscape {
      margin: 0 auto;
      width: 468px;
    }
    @include theme.for-tablet-up {
      margin: 0 auto;
      max-width: 600px;
      position: relative;
    }
    @include theme.for-desktop-up {
      margin: 0 auto;
      max-width: 1270px;
      padding-right: 635px;
      position: relative;
    }
  }

  h2 {
    font-size: 28px;
    font-weight: 900;
    line-height: 36px;
    margin-top: 0.5rem;

    @include theme.for-tablet-up {
      font-size: 44px;
      line-height: 52px;
    }
  }
  p {
    @include theme.for-tablet-up {
      font-size: 23px;
      line-height: 32px;
    }
  }
  button {
    margin-top: 25px;

    @include theme.for-tablet-up {
      margin-top: 75px;
      max-width: 300px;
    }
  }
}
