@use 'styles/theme';

.description {
  border-bottom: 1px solid theme.$color-border-gray;
  margin-bottom: 20px;
  padding-bottom: 20px;
  text-align: left;

  .icon {
    color: theme.$color-comptia-red;
    margin-right: 5px;
    vertical-align: bottom;
  }

  small {
    font-size: 14px;
  }
}

.edit {
  background: none;
  background-color: theme.$color-blue;
  border: none;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  padding: 6px 20px;

  &:hover {
    background-color: darken(theme.$color-primary, 5%);
  }

  &.cancel {
    background-color: theme.$color-light-gray;
    color: theme.$color-dark-gray;
    &:hover {
      background-color: darken(theme.$color-light-gray, 5%);
    }
  }
}

.grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;

  > * {
    grid-column: span 12;
  }

  .suffix,
  .zip {
    grid-column: span 4;
  }

  .code {
    grid-column: span 4;
  }

  .phone {
    grid-column: span 8;
  }
}

.alternate {
  text-align: left;

  @include theme.for-tablet-up {
    margin-top: -1rem;
  }
}

.button {
  margin-top: 20px;
}
