@use 'styles/theme';

.card {
  background-color: #a0dab3;
  border-radius: 14px;
  margin-top: -1.25rem;
  padding: 2rem 1.5rem;
  position: relative;
  text-align: left;
  z-index: 1;

  .button {
    margin-top: 1rem;
  }

  @include theme.for-tablet-up {
    border-radius: 20px;
    height: 388px;
    margin-top: 0;
    position: absolute;
    right: 0.5rem;
    top: 0;
    width: calc(50% - 1rem);
  }
  @include theme.for-desktop-up {
    border-radius: 20px;
    margin-top: 0;
    max-width: 488px;
    padding: 2.5rem 2.667rem;
    position: absolute;
    right: 75px;
    top: 0;

    .button {
      margin-top: auto;
      width: 62%;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  h2 {
    margin-bottom: 0.75rem;
  }
  p {
    margin-bottom: 0.75rem;

    &.link {
      margin-bottom: 0;
      margin-top: 0.75rem;
    }
  }
}
