@use 'styles/theme';

.grid {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, minmax(0, 50%));
  // padding: 1.5rem .5rem;
  width: 100%;

  .active {
    background-color: theme.$color-blue;
    border-radius: 26px 26px 0 0;
    color: #fff;
  }
}

.container {
  background-color: #fff;
  border: 1px solid #004f71;
  border-radius: 0 0 20px 20px;
  padding: 2.75rem 2.667rem 2.667rem 2.667rem;
}
