.grid {
  display: grid;
  gap: 20px;

  .button {
    margin-top: 5rem;
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
}
